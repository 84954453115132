<template>
  <a-modal :title="modalTitle" class="page-row-item" width="80%" v-model="visible" @cancel="handleClose">
    <!-- <div class="page-search">
      <div class="page-search-item">
        <span class="page-search-item-label">工种/专业</span>
        <projectCascader
          :demandId.sync="query.demandId"
          :projectDepartmentId.sync="query.projectDepartmentId"
          :projectId.sync="query.projectId"
          style="width: 100%"
        />
      </div>
      <div class="page-search-item">
        <span class="page-search-item-label">员工姓名</span>
        <a-input v-model="query.realName" allowClear placeholder="员工姓名" />
      </div>
      <div class="page-search-item">
        <span class="page-search-item-label">联系电话</span>
        <a-input v-model="query.phone" allowClear placeholder="联系电话" />
      </div>
      <div class="page-search-item last-item">
        <a-button type @click="handleReset">重置</a-button>
        <a-button type="primary" @click="$refs.table.initPage()">查询</a-button>
      </div>
    </div> -->
    <div class="page-content">
      <p-table
        ref="table"
        :columns="tableColumns"
        :sourceData="getDataApi"
        :scroll="{ x: 3000, y: 400 }"
        row-key="id"
        :fileName="modalTitle"
      >
        <div slot="headerLeft"></div>
      </p-table>
    </div>
    <template slot="footer">
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <a-button type="" @click="handleClose">取消</a-button>
      </div>
    </template>
  </a-modal>
</template>

  <script>
  import { pageSalaryInfo, addBatchCommission, salaryRefund, salaryReload } from '@/api/recruitUse'
  import projectCascader from '@/components/user-salary/project-cascader.vue'
  import { statusOptions, payOptions } from '@/components/user-salary/utils'

  export default {
    name: 'RowItem',
    components: {
      projectCascader
    },
    data () {
      return {
        visible: false,
        statusOptions,
        payOptions,
        query: {},
        columns: [
          {
            title: '姓名',
            dataIndex: 'realName',
            download: true,
            width: 70,
            fixed: 'left'
          },
          {
            title: '注册账户',
            dataIndex: ['userInfoDto', 'username'],
            fixed: 'left',
            download: true,
            width: 100
          },
          {
            title: '联系电话',
            dataIndex: ['userInfoDto', 'phone'],
            download: true,
            fixed: 'left',
            width: 100
          },
          {
            title: '性别',
            dataIndex: ['userInfoDto', 'sex'],
            download: true,
            width: 50,
            customRender: (text) => (text == 1 ? '女' : '男')
          },
          {
            title: '年龄',
            download: true,
            dataIndex: ['userInfoDto', 'age'],
            width: 50
          },
          {
            title: '岗位薪资',
            download: true,
            dataIndex: ['publishDemandDto', 'salary'],
            customRender: (t, r) => {
              if (r.publishDemandDto) {
                return r.publishDemandDto.flag == 1 ? r.publishDemandDto.salary : r.publishDemandDto.wbProjectSalary
              }
            },
            width: 120
          },
          {
            title: '工种/专业',
            download: true,
            width: 120,
            dataIndex: ['publishDemandDto', 'positionName']
          },
          {
            title: '项目名称',
            download: true,
            width: 120,
            dataIndex: ['publishDemandDto', 'projectName']
          },
          {
            title: '项目部名称',
            download: true,
            width: 120,
            dataIndex: ['publishDemandDto', 'projectDepartmentName']
          },
          {
            title: '银行卡号',
            width: 160,
            download: true,
            dataIndex: 'bankCard'
          },
          {
            title: '薪资发放周期',
            width: 160,
            download: true,
            dataIndex: 'date'
          },
          {
            title: '出勤（天）',
            width: 100,
            download: true,
            dataIndex: 'day'
          },
          {
            title: '应发（元）',
            width: 100,
            download: true,
            dataIndex: 'payable'
          },
          {
            title: '加班时间（小时）',
            width: 100,
            download: true,
            dataIndex: 'workOvertime'
          },
          {
            title: '加班费用（元）',
            download: true,
            width: 100,
            dataIndex: 'workOvertimePayment'
          },
          {
            title: '扣款（元）',
            download: true,
            width: 100,
            dataIndex: 'deduction'
          },
          {
            title: '实发（元）',
            download: true,
            width: 100,
            dataIndex: 'realPay'
          },
          {
            title: '上传人',
            download: true,
            width: 100,
            dataIndex: 'createBy'
          },
          {
            title: '上传时间',
            width: 160,
            download: true,
            dataIndex: 'createTime'
          },
          {
            title: '结算时间',
            download: true,
            width: 160,
            dataIndex: 'closeTime'
          },
          {
            title: '结算状态',
            width: 100,
            download: true,
            dataIndex: 'status',
            customRender: (t, r) => {
              const data = this.statusOptions.find((item) => item.value == t) || {}
              if (r.refundStatus == 1) {
                  return `${data.label}（已退款）`
              }
              return data.label
            }
          },
          {
            title: '发放状态（财务系统）',
            width: 100,
            download: true,
            dataIndex: 'thirdPartyStatus',
            customRender: (t) => {
              const data = this.payOptions.find((item) => item.value == t) || {}
              return data.label
            }
          },
          {
            title: '发放备注',
            width: 120,
            download: true,
            dataIndex: 'remark'
          }
        ],
        id: null,
        groupName: null,
        modalTitle: ''
      }
    },
    computed: {
      tableColumns () {
        return this.columns.map((item) => {
          const dataIndex = item.dataIndex
          return {
            ...item,
            key: Array.isArray(dataIndex) ? dataIndex.join(',') : dataIndex,
            download: item.title !== '操作'
          }
        })
      }
    },
    methods: {
      handleReset () {
        this.query = {}
        this.$refs.table.initPage()
      },
      showModal (row) {
        this.id = row.id
        this.groupName = row.name
        this.modalTitle = row.name + '详情'
        this.visible = true
        setTimeout(() => {
          this.$refs.table.initPage()
        }, 200)
      },
      handleClose () {
        this.id = null
        this.groupName = null
        this.$refs.table.refresh()
        this.visible = false
      },
      getDataApi (params) {
        if (this.id) {
          return pageSalaryInfo({
            ...params,
            groupId: this.id,
            ...this.query
          })
        } else {
          return Promise.resolve({})
        }
      }
    }
  }
  </script>

  <style lang="less">
  .page-row-item {
    .page-search {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      &-item {
        flex: 1;
        margin-right: 24px;
        display: flex;
        align-items: center;
        & + .last-item {
          margin-right: 0;
          justify-content: flex-end;
        }
        &-label {
          position: relative;
          display: inline-block;
          margin-right: 24px;
          white-space: nowrap;
          &::after {
            content: '：';
            display: block;
            position: absolute;
            width: 10px;
            height: 100%;
            right: -12px;
            top: 0;
          }
        }
      }
    }
    .page-content {
      padding: 0 0 40px 0;
    }
  }
  </style>
