export const statusOptions = [
    {
      value: 0,
      label: '待结算'
    },
    {
      value: 1,
      label: '已结算'
    }
  ]

  export const typeOptions = [
    {
      value: 1,
      label: '业务外包'
    },
    {
      value: 2,
      label: '灵活用工'
    }
  ]

  export const payOptions = [
    {
      value: 0,
      label: '未发放'
    },
    {
      value: 1,
      label: '发放中'
    },
    {
      value: 2,
      label: '发放成功'
    },
    {
      value: 3,
      label: '发放失败'
    }
  ]
