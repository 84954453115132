<template>
  <a-cascader
    :options="options"
    v-model="checkValue"
    placeholder="请选择工种/专业"
    allowClear
    :loadData="loadData"
    change-on-select
    @change="handleChange"
  />
</template>

<script>
import {
  employmentEmployDepartment,
  enterpriseEmploymentManagement,
  enterpriseEmploymentPosition
} from '@/api/recruitUse'
export default {
  name: 'ProjectCascader',
  data () {
    return {
      options: [],
      checkValue: []
    }
  },
  created () {
    employmentEmployDepartment().then((res) => {
      this.options = this.formatOptions(
        res.data,
        ['projectDepartmentId', 'projectDepartment'],
        1
      )
    })
  },
  methods: {
    loadData (selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      const { level, value } = targetOption
      const fun =
        level == 1 ? enterpriseEmploymentManagement : enterpriseEmploymentPosition
      const params = {
        [level == 1 ? 'projectDepartmentId' : 'projectId']: value
      }
      targetOption.loading = true
      fun(params)
        .then((res) => {
          const keyArr =
            level == 1 ? ['projectId', 'projectName'] : ['demandId', 'position']
          targetOption.children = this.formatOptions(res.data, keyArr, level + 1)

          this.options = [...this.options]
            console.log(this.options, 'options')
        })
        .finally(() => {
          targetOption.loading = false
        })
    },
    formatOptions (list, keyArr, level) {
      const [valueKey, labelKey] = keyArr
      return list.map((item) => ({
        value: item[valueKey],
        label: item[labelKey],
        level,
        isLeaf: level === 3
      }))
    },
    handleChange (value) {
      console.log(value, 'value')
      if (value && value.length < 2) {
        this.checkValue = []
      }

      const emitValue = value && value.length < 2 ? [] : value
      const [projectDepartmentId = '', projectId = '', demandId = ''] = emitValue
      this.$emit('update:projectDepartmentId', projectDepartmentId)
      this.$emit('update:projectId', projectId)
      this.$emit('update:demandId', demandId)
    }
  }
}
</script>
